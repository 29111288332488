import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

interface LangSelectProps {
  lang: string;
  children: ReactElement;
}

const LangSelect = ({ lang, children }: LangSelectProps): ReactElement => {
  const { i18n } = useTranslation();
  if (i18n.language !== lang) {
    i18n.changeLanguage(lang);
  }

  return children;
};

export default LangSelect;
