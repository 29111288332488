import React from 'react';

const ErrorPage = () => {
  return (
    <div>
      <h1>Ooops! Something went wrong.</h1>
      <p>Please try to refresh this page.</p>
    </div>
  );
};

export default ErrorPage;
