import React, { Component } from 'react';

interface ErrorBoundaryProps {
  ErrorComponent: React.ComponentClass | React.StatelessComponent;
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: any) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.error(error, errorInfo); // eslint-disable-line no-console
  }

  render() {
    const { hasError } = this.state;
    const { children, ErrorComponent } = this.props;

    return hasError ? <ErrorComponent /> : children;
  }
}

export default ErrorBoundary;
